import { Injectable } from '@angular/core';
import { UNSAVED_CHANGES_DIALOG_CONFIG } from '@core/constants/dialog.constants';
import { ValueChangeDetector } from '@core/models/value-change-detector.models';
import { NapaDialogCloseEvent } from '@shared/modules/dialog/models/dialog.models';
import { DialogApi } from '@shared/modules/dialog/services/dialog.api';
import { Observable, map, of, take } from 'rxjs';

@Injectable()
export class UnsavedChangesGuard {
  constructor(private dialogApi: DialogApi) {}

  canDeactivate(component: ValueChangeDetector): Observable<boolean> {
    if (component.hasChanges) {
      return this.dialogApi
        .openGenericPopup(UNSAVED_CHANGES_DIALOG_CONFIG)
        .afterClosed()
        .pipe(
          take(1),
          map((event: NapaDialogCloseEvent | undefined) => !!event?.primaryBtnClicked)
        );
    }

    return of(true);
  }
}
