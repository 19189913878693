import { NapaDialogConfig } from '@shared/modules/dialog/models/dialog.models';

export const UNSAVED_CHANGES_DIALOG_CONFIG: NapaDialogConfig = {
  title: 'Are you sure you want to leave?',
  body: 'You have unsaved changes. Are you sure you want to leave this page?',
  hasPrimaryBtn: true,
  hasSecondaryBtn: true,
  closeOnButtonClick: true,
  secondaryBtnText: 'Cancel',
  primaryBtnText: 'Confirm',
};
